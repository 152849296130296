import 'cookieconsent'
import '../node_modules/cookieconsent/build/cookieconsent.min.css'

const ID_ANALITICS = 'UA-121205685-1'


class RGPD {

  constructor() {
    const self = this

    window.cookieconsent.initialise({
      palette: {
        popup: {
          "background": "#2b3688"
        },
        button: {
          "background": "#78be20",
          "text": "#ffffff"
        }
      },
      theme: "classic",
      position: "bottom-left",
      type: "opt-in",
      content: {
        "message": " Ce site utilise des cookies afin d’améliorer votre expérience et optimiser nos sites et services. Un cookie est un petit fichier de texte transféré à partir d’un serveur web vers votre navigateur web ou votre disque dur lorsque vous visitez un site web. Si votre navigateur web est configuré pour accepter les cookies, ils seront stockés dans le navigateur web ou le disque dur jusqu’à ce que la date d’expiration soit dépassée ou jusqu’à ce que vous supprimiez les cookies vous-même. Nous utilisons les cookies de Google Tag Manager pour l'analytics. Ces cookies sont obligatoires pour le bon fonctionnement du site. Par défaut, l'adresse IP de l'utilisateur est anonymisée. Les cookies Sylius sont indispensables pour le bon fonctionnement du cycle d'achat en ligne. ",
        "dismiss": "Je refuse",
        "deny": "Je refuse",
        "allow": "Accepter les cookies",
        "link": "Lire notre charte de protection de vos données personnelles",
        "href": "https://www.campingcarpark.com/fr_FR/info/charte-de-protection-de-vos-donnees-personnelles"
      },
      revokable: true,
      revokeBtn: "<div class ='cc-revoke'> Politique de cookies </div>",
      animateRevokable: true,

      onInitialise: function (status) {

        let didConsent = this.hasConsented();
        if (didConsent) {
          self.initAnalitics(false)
        }
        if (!didConsent) {
          self.initAnalitics(true)
        }
      },
      onStatusChange: function(status, chosenBefore) {

        let didConsent = this.hasConsented();
        if (didConsent) {
          self.initAnalitics(false)
          location.reload()
        }
        if (!didConsent) {
          self.initAnalitics(true)
          location.reload()
        }
      },
      onRevokeChoice: function() {

        let type = this.options.type;
        if (type === 'opt-in') {
          self.initAnalitics(true)
        }
        if (type === 'opt-out') {
          self.initAnalitics(false)
        }
      },
      law: {
        regionalLaw: false,
      },
      location: true,
    });
  }

  initAnalitics(bool){

    window.ga = function () { ga.q.push(arguments) }; ga.q = []; ga.l = +new Date;
    window['ga-disable-'+ ID_ANALITICS] = bool;
    ga('create', 'UA-121205685-1', 'auto'); ga('set', 'anonymizeIp', true); ga('set', 'transport', 'beacon'); ga('send', 'pageview')
  }
}

export {RGPD}



