"use strict";
import 'regenerator-runtime/runtime'
import {Map} from './map';
import {RGPD} from './rgpd';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGxlY29tdCIsImEiOiJja2h1amhudHowNXVxMnhtbzA3bjdwYzV3In0.9X8KE8Ii0ex43_mCyixaXg';


document.addEventListener('DOMContentLoaded', () => {

  new Map('map', MAPBOX_TOKEN);
  new RGPD();

});


