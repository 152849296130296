import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

 let apiHostname = '';
if(window.location.hostname === 'localhost'){
  apiHostname = "https://www-local.campingcarpark.com"
}else{
  apiHostname = "https://www.campingcarpark.com";
}

const campingPath = require('../img/map/icon-camping.png');
const URL_LOCATIONS_POSITION = apiHostname + "/shop-api/cmv_locations/maps";
const URL_LOCATION_INFO = apiHostname + "/shop-api/locations/";
const URL_CCP_WEBSITE = apiHostname;

class Map {

  constructor(container, restrictedToken) {
    this.container = container;
    this.token = restrictedToken;
    this.init();

  }

  init() {
    mapboxgl.accessToken = this.token;
    this.iconSize = [40, 40];
    this.map = this.mapBox();
    this.controls();
    this.getLocations();
    // this.addSource({"id":"23"})
    this.addIcons();
    this.addPopup();
    this.marker();
  }

  mapBox() {
    return new mapboxgl.Map({
      container: this.container,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [2.463345, 46.582268], // centre de la France
      zoom: 5,
      attributionControl: false,
      scrollZoom: false,
    });
  }

  controls() {
    // Navigation
    this.map.addControl(new mapboxgl.NavigationControl({
      showCompass: false
    }), 'top-left');

    // Scale
    this.map.addControl(new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric'
    }));

    this.map.addControl(new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    }), 'bottom-right');
  }

  getLocations() {
    let self = this
    const xhr = new XMLHttpRequest(),
      method = "GET"
    xhr.open(method, URL_LOCATIONS_POSITION, true);
    xhr.onreadystatechange = function() {
      if(xhr.readyState === 4 && xhr.status === 200) {
        self.addSource(JSON.parse(xhr.response))
      }
    };
    xhr.send();

  }

  addSource(locations) {
    console.log(this.map)
    this.map.addSource('locations', locations)
    this.addLayer()
  }

  addLayer() {
      this.map.addLayer({
        'id': 'locations',
        'type': 'symbol',
        'source': 'locations',
        'layout': {
          'icon-image': [
            'case',
            ['==', ['get', 'type'], 'camping'], 'camping',
            ''
          ],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      })
  }

  addIcons() {
    this.map.on('load', () => {
      this.map.loadImage(campingPath, (error, image) => {
        if (error) throw error;
        this.map.addImage('camping', image);
      })
    })
  }


  setPopup(coordinates, location) {

    return new mapboxgl.Popup({
      offset: 25,
      closeButton: false,
      maxWidth: '350px',
    })
      .setLngLat(coordinates)
      .setHTML(this.render(location))
      .addTo(this.map)
  }



  render(location) {
    let bookTrans = document.getElementById('map').dataset.bookTrans;
    let infoTrans = document.getElementById('map').dataset.moreInfoTrans;
    let image = location.images[0].mobileUrl;
    let link = URL_CCP_WEBSITE + location.linkUrl;
console.log(location);
    return `
      <div class="map-card">
        <div class="card-header">
            <img src="${image}" alt=""/>
        </div>
        <h2 class="title">
          <span class="dept">${location.postalCode.substring(0, 2)} -</span>
          <span class="name">${location.name}</span>
        </h2>
        <div class="price-pitches flex">
            <div class="pitch">
            <span class="value">${location.availablePitchesNumber}</span>/${location.totalPitchesNumber}
            places
          </div>
          <div class="price">
            <span class="value">${location.currentPrice.allTaxesIncludedParkingPrice}&nbsp;€</span>
            &nbsp;/&nbsp;nuit
          </div>
        </div>
        <div class="card-footer flex">
            <a class="btn info" href="${link}" target="_blank">` + infoTrans +`</a>
            <a class="btn book" href="${link}" target="_blank">` + bookTrans +`</a>
        </div>
      </div>
    `;
  }

  substring(text) {
    if (text.length > 25) {
      return text.substring(0, 25) + ' ...';
    }
    return text;
  }

  addPopup() {
    let $url = URL_LOCATION_INFO;
    let $locale = document.getElementById('map').dataset.locale;
    let self = this

    this.map.on('load', () => {
      this.map.on('click', 'locations', e => {
        let coordinates = e.features[0].geometry.coordinates.slice();
        const xhr = new XMLHttpRequest(),
          method = "GET"
        xhr.open(method, URL_LOCATION_INFO + e.features[0].properties.id , true);
        xhr.onreadystatechange = function() {
          if(xhr.readyState === 4 && xhr.status === 200) {
            self.setPopup(coordinates, JSON.parse(xhr.response) )
          }
        };
        xhr.send();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
      })
    })
  }

  marker() {
    this.map.on('load', () => {
      this.map.on('mouseenter', 'locations', e => {
        e.target.getCanvas().style.cursor = 'pointer';
      })

      this.map.on('mouseleave', 'locations', e => {
        e.target.getCanvas().style.cursor = '';
      })
    })
  }

}
export {Map}
